import EmblaCarousel, { EmblaOptionsType, EmblaCarouselType } from '../../../../../embla-carousel/packages/embla-carousel'
// import EmblaCarousel, { EmblaOptionsType } from 'embla-carousel'

// import {
//   addPrevNextBtnsClickHandlers,
//   addTogglePrevNextBtnsActive,
//   addDotBtnsAndClickHandlers,
// } from './arrows-dots-buttons'
// import '../css/base.css'
// import '../css/sandbox.css'
// import '../css/embla.css'


enum ViewModes {
	small,
	middle,
	big
}

const getViewMode = (): number => {
	let mode: number
	// This is the width without the scrollbar, but css media screen estimates it with the scrollbar.
	// So use window.innerWidth
	// let viewWidth = document.documentElement.clientWidth
	let viewWidth = window.innerWidth
	if (viewWidth > 1200) {
		mode = ViewModes.big
	} else if (viewWidth > 550) {
		mode = ViewModes.middle
	} else {
		mode = ViewModes.small
	}
	console.log('Mode: ', mode);
	return mode
}

export const addPrevNextBtnsClickHandlers = (
	emblaApi: EmblaCarouselType,
	prevBtn: HTMLElement,
	nextBtn: HTMLElement,
): (() => void) => {
	const scrollPrev = () => emblaApi.scrollPrev()
	const scrollNext = () => emblaApi.scrollNext()
	prevBtn.addEventListener('click', scrollPrev, false)
	nextBtn.addEventListener('click', scrollNext, false)

	return (): void => {
		prevBtn.removeEventListener('click', scrollPrev, false)
		nextBtn.removeEventListener('click', scrollNext, false)
	}
}

export const addTogglePrevNextBtnsActive = (
	emblaApi: EmblaCarouselType,
	prevBtn: HTMLElement,
	nextBtn: HTMLElement,
): (() => void) => {
	const togglePrevNextBtnsState = (): void => {
		if (emblaApi.canScrollPrev()) prevBtn.removeAttribute('disabled')
		else prevBtn.setAttribute('disabled', 'disabled')

		if (emblaApi.canScrollNext()) nextBtn.removeAttribute('disabled')
		else nextBtn.setAttribute('disabled', 'disabled')
	}

	emblaApi
		.on('select', togglePrevNextBtnsState)
		.on('init', togglePrevNextBtnsState)
		.on('reInit', togglePrevNextBtnsState)

	return (): void => {
		prevBtn.removeAttribute('disabled')
		nextBtn.setAttribute('disabled', 'disabled')
	}
}

const estimateOptions = (): EmblaOptionsType => {
	let options: EmblaOptionsType
	let _mode = getViewMode()

// Will not loop if there is not enough content - does not loop on desktop
	if (_mode === ViewModes.small) {
		options = {active: true, loop: false, startIndex: 0, inViewThreshold: 1}
	} else if (_mode === ViewModes.middle) {
		options = {active: true, loop: false, startIndex: 1, inViewThreshold: 3}
	} else {
		options = {active: false, loop: false, startIndex: 0, inViewThreshold: 8}
	}
	return options
}

// const OPTIONS: EmblaOptionsType = {}
let options = estimateOptions()

const emblaNode = <HTMLElement>document.querySelector('.embla_benefits')
const viewportNode = <HTMLElement>emblaNode.querySelector('.embla_benefits__viewport')
const prevBtn = <HTMLElement>emblaNode.querySelector('.embla_benefits__button--prev')
const nextBtn = <HTMLElement>emblaNode.querySelector('.embla_benefits__button--next')
const dotsNode = <HTMLElement>document.querySelector('.embla_benefits__dots')
let emblaApi = EmblaCarousel(viewportNode, options)

const removePrevNextBtnsClickHandlers = addPrevNextBtnsClickHandlers(
  emblaApi,
  prevBtn,
  nextBtn,
)
const removeTogglePrevNextBtnsActive = addTogglePrevNextBtnsActive(
  emblaApi,
  prevBtn,
  nextBtn,
)

let timerResize: number
let prevMode: number

const onInitOnce = (): void => {
	prevMode = getViewMode()
}

const onInit = (): void => {
	let _mode = getViewMode()
	if (prevMode !== _mode) {
		prevMode = _mode
		if (typeof timerResize !== 'undefined') {
			clearInterval(timerResize)
		}
		timerResize = setTimeout(() => {
			options = estimateOptions()
			// console.log('reiniting...')
			emblaApi.reInit(options)
			// if (_mode === ViewModes.big) {
			// 	// wait a little to complete the animations (if any)
			// 	setTimeout(() => {
			// 		let containerNode = <HTMLElement>viewportNode.querySelector('.embla_benefits__container')
			// 		containerNode.setAttribute('style', '')
			// 	}, 1500)
			// }
		}, 1500)
	}

}

// const onResizeHandler = (): void => {
// }

// const onBeforeActivate = (): void => {
//
// }

const onDestroyHandler = (): void => {
	let containerNode = <HTMLElement>viewportNode.querySelector('.embla_benefits__container')
	containerNode.setAttribute('style', '')
}

export const addDotBtnsAndClickHandlers = (
	emblaApi: EmblaCarouselType,
	dotsNode: HTMLElement,
): (() => void) => {
	let dotNodes: HTMLElement[] = []

	const addDotBtnsWithClickHandlers = (): void => {
		dotsNode.innerHTML = emblaApi
			.scrollSnapList()
			.map(() => '<button class="embla_benefits__dot" type="button"></button>')
			.join('')

		dotNodes = Array.from(dotsNode.querySelectorAll('.embla_benefits__dot'))
		dotNodes.forEach((dotNode, index) => {
			dotNode.addEventListener('click', () => emblaApi.scrollTo(index), false)
		})
	}

	const toggleDotBtnsActive = (): void => {
		const previous = emblaApi.previousScrollSnap()
		const selected = emblaApi.selectedScrollSnap()
		dotNodes[previous].classList.remove('embla_benefits__dot--selected')
		dotNodes[selected].classList.add('embla_benefits__dot--selected')
	}

	const hideOrShow = (): void => {
		let _mode = getViewMode()

		if (_mode === ViewModes.big) {
			dotsNode.style.display = 'none'
			if (prevBtn.parentElement) {
				prevBtn.parentElement.style.display = 'none'
			}
			if (nextBtn.parentElement) {
				nextBtn.parentElement.style.display = 'none'
			}
		} else {
			dotsNode.style.display = 'flex'
			if (prevBtn.parentElement) {
				prevBtn.parentElement.style.display = 'block'
			}
			if (nextBtn.parentElement) {
				nextBtn.parentElement.style.display = 'block'
			}
		}
	}

	emblaApi
		.on('init', addDotBtnsWithClickHandlers)
		.on('reInit', addDotBtnsWithClickHandlers)
		.on('init', toggleDotBtnsActive)
		.on('reInit', toggleDotBtnsActive)
		.on('init', hideOrShow)
		.on('reInit', hideOrShow)
		.on('select', toggleDotBtnsActive)

	return (): void => {
		dotsNode.innerHTML = ''
	}
}

const removeDotBtnsAndClickHandlers = addDotBtnsAndClickHandlers(
  emblaApi,
  dotsNode,
)

emblaApi
  .on('destroy', removePrevNextBtnsClickHandlers)
  .on('destroy', removeTogglePrevNextBtnsActive)
  .on('destroy', removeDotBtnsAndClickHandlers)
	.on('destroy', onDestroyHandler)
	// .on('resize', onResizeHandler)
	.on('init', onInitOnce)
	.on('reInit', onInit)
	// .on('beforeActivate', onBeforeActivate)
